import { AxiosInstance } from 'axios';
import { useHttp } from '@Libs/http';
import { responseInterface } from 'swr';

interface payloadDevice {
    platform: string;
    deviceId: string;
    fcmToken: string;
    fcmProjectId: string;
}

const defaultApi = (http: AxiosInstance) => ({
    getInitData: async () => {
        const response = await http.get('/default/init_data');
        return response.data;
    },
    getListRegions: async () => {
        const response = await http.get('/default/list_regions');
        return response.data;
    },
    getListDistricts: async (regionName) => {
        const response = await http.get('/default/list_districts', {
            params: { regionCode: regionName },
        });

        return response.data;
    },
    getListWards: async (regionName, districtName) => {
        const response = await http.get('/default/list_wards', {
            params: { regionCode: regionName, districtCode: districtName },
        });

        return response.data;
    },
    updateDevice: async (payload: payloadDevice) => {
        return await http.post('default/update_device', payload);
    },
});

const INIT_DATA_KEY = 'init_data';
export const useInitData = (key = INIT_DATA_KEY): responseInterface<any, any> => useHttp(key, async ({ userHttp }) => await defaultApi(userHttp).getInitData());

export const KEY_LIST_REGIONS = '/default/list_regions';
export const useListRegions = (key = KEY_LIST_REGIONS): responseInterface<any, any> =>
    useHttp(key, async ({ userHttp }) => {
        const response = await defaultApi(userHttp).getListRegions();
        return response.regions || [];
    });

export const KEY_LIST_DISTRICTS = '/default/list_districts';
export const useListDistricts = (regionName: string, key = KEY_LIST_DISTRICTS): responseInterface<any, any> =>
    useHttp(key, async ({ userHttp }) => {
        const response = await defaultApi(userHttp).getListDistricts(regionName);
        return response.districts || [];
    });

export const KEY_LIST_WARDS = '/default/list_wards';
export const useListWards = (regionName: string, districtName: string, key = KEY_LIST_WARDS): responseInterface<any, any> =>
    useHttp(key, async ({ userHttp }) => {
        const response = await defaultApi(userHttp).getListWards(regionName, districtName);
        return response.wards || [];
    });

export default defaultApi;
