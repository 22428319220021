// API
import { http } from '@Libs/http';
import defaultApi from '@Libs/api/user/default';

// firebase
import 'firebase/messaging';
import 'firebase/firestore';
import firebase from 'firebase/app';

// Config
import firebaseConfig from '@Config/firebase';

// NPM
import md5 from 'md5';
import Cookies from 'js-cookie';

// TypeScript
interface payloadType {
    platform: string;
    deviceId: string;
    fcmToken: string;
    fcmProjectId: string;
}

const generateDeviceID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

const FCMInit = async (serviceWorkerRegistration: ServiceWorkerRegistration) => {
    if (!firebase.apps?.length) {
        firebase.messaging.isSupported();
        firebase.initializeApp(firebaseConfig);
        try {
            const fcmToken = await firebase.messaging().getToken({
                serviceWorkerRegistration,
            });

            const token = Cookies.get('token') || '';

            const hashedFCMToken = md5(`${fcmToken}.${token}`);
            const prevHashedFCMToken = localStorage.getItem('hashedFCMToken') || '';

            if (hashedFCMToken !== prevHashedFCMToken) {
                localStorage.setItem('hashedFCMToken', hashedFCMToken);

                const deviceId = generateDeviceID();

                return { fcmToken, deviceId };
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('error FCMInit', error);
        }
    }
};

const updateDeviceInfo = async (fcmToken: string, deviceId: string) => {
    if (deviceId && fcmToken) {
        const payload: payloadType = {
            deviceId,
            fcmToken,
            platform: 'web',
            fcmProjectId: process.env.FIREBASE_PROJECTID,
        };

        try {
            await defaultApi(http).updateDevice(payload);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('error updateDeviceInfo', error);
        }
    }
};

export const useFirebaseMessaging = (): void => {
    (async () => {
        if ('serviceWorker' in navigator) {
            try {
                const serviceWorker = await navigator.serviceWorker.register('/sw.js');

                const result: { deviceId: string; fcmToken: string } = await FCMInit(serviceWorker);
                const { deviceId, fcmToken } = result ?? {};

                await updateDeviceInfo(fcmToken, deviceId);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('error useFirebaseMessaging', error);
            }
        }
    })();

    return null;
};
