import { useEffect, useState, forwardRef, ReactElement, FunctionComponent, useImperativeHandle } from 'react';

interface TimeoutProps {
    step?: number | 0;
    milliseconds: number;
    children: ({ duration: number, end: boolean }) => ReactElement | null;
}

const Timeout: FunctionComponent<any & TimeoutProps> = forwardRef((props, ref) => {
    let timeoutId = null;
    const [end, setEnd] = useState(false);
    const [duration, setDuration] = useState(props.milliseconds);

    useImperativeHandle(ref, () => ({
        reset: () => {
            setDuration(props.milliseconds);
            setEnd(false);
        },
    }));

    useEffect(() => {
        setDuration(props.milliseconds);
    }, [props.milliseconds]);

    useEffect(() => {
        if (duration > 0) {
            if (!timeoutId) {
                timeoutId = setTimeout(() => {
                    setDuration(duration - 1000);
                }, props.step || 1000);
            }
        } else {
            setEnd(true);
        }

        return () => clearTimeout(timeoutId);
    }, [duration, end]);

    return props.children({ duration, end });
});

export default Timeout;
