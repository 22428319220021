import React, { FunctionComponent } from 'react';

// Components
import Link from 'next/link';
import Image from '@Components/Utils/Image';
import { useViewDetected, ViewMedia } from '@Components/Utils/ViewDetected';

// Icons

// Utilities
import classnames from 'classnames';

// Styles
import styles from './HeaderNavbar.module.scss';

const Header: FunctionComponent = () => {
    const { deviceType } = useViewDetected();

    const className = classnames([
        styles.wrapper,
        styles[deviceType],
        {
            navbar: deviceType === 'desktop',
        },
    ]);

    return (
        <div className={className}>
            <div className={styles.menu}>
                <Link href="/">
                    <a href="#" className={styles.brand}>
                        <Image src="/assets/logo.svg" width={'113px'} height={'36px'} />
                    </a>
                </Link>

                <ViewMedia is={'desktop'} dynamic={() => import('./Components/DesktopNavbar')} dynamicOptions={{ ssr: false }} />

                <ViewMedia is={['mobile', 'tablet']} dynamic={() => import('./Components/MobileNavbar')} dynamicOptions={{ ssr: false }} />
            </div>
        </div>
    );
};

export default Header;
