import { MouseEvent, FunctionComponent, useCallback } from 'react';
import classnames from 'classnames';

// Components
import Image from '@Components/Utils/Image';
import Anchor from '@Components/Utils/Anchor';
import ImgLazy from '@Components/Utils/Lazy/Image';
import LayoutContainer from '@Components/Layout/Components/Container';
import { ViewMedia, useViewDetected } from '@Components/Utils/ViewDetected';

// Utils
import { getUrl } from '@Libs/utils';

// Styles
import styles from './MiddleFooter.module.scss';

// Config
import host from '@Config/host';
import { useAskQuestion } from '@Pages/hoi-dap/Context';

const middleFooter = {
    eDoctor: [
        {
            title: 'Về chúng tôi',
            link: getUrl('/ve-chung-toi.html'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Liên hệ',
            link: getUrl('/lien-he'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Blog',
            link: '',
            target: '_self',
            isComing: true,
            isHandle: false,
        },
    ],
    service: [
        {
            title: 'Đặt câu hỏi miễn phí',
            link: '/hoi-dap',
            target: '_self',
            isComing: false,
            isHandle: true,
        },
        {
            title: 'Đặt khám tổng quát',
            link: getUrl('/kham-tong-quat'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Xem kết quả xét nghiệm',
            link: getUrl('/dat-hen/lich-su.html'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Mua thuốc trực tuyến',
            link: getUrl('/', host.epharmacyDomain),
            rel: 'noreferrer',
            target: '_blank',
        },
        // {
        //     title: 'Theo dõi sức khỏe',
        //     link: '',
        //     target: '_self',
        //     isComing: false,
        //     isHandle: false,
        // },
        // {
        //     title: 'Đặt lịch nhắc nhở',
        //     link: '',
        //     target: '_self',
        //     isComing: false,
        //     isHandle: false,
        // },
        // {
        //     title: 'Chat với Bác sĩ',
        //     link: 'https://dl.edoctor.io/taiapp',
        //     target: '_blank',
        //     isComing: false,
        //     isHandle: false,
        // },
    ],
    info: [
        {
            title: 'Tra cứu loại bệnh',
            link: '/tra-cuu/benh',
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Tra cứu thuốc',
            link: '',
            target: '_self',
            isComing: true,
            isHandle: false,
        },
        {
            title: 'Tìm phòng khám',
            link: '/tra-cuu/phong-kham',
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Tìm kiếm Bác sĩ',
            link: '/tra-cuu/bac-si',
            target: '_self',
            isComing: false,
            isHandle: false,
        },
    ],
    partner: [
        {
            target: '_self',
            isComing: false,
            isHandle: false,
            link: '/bac-si',
            title: 'Dành cho Bác sĩ',
        },
        {
            target: '_self',
            isComing: false,
            isHandle: false,
            title: 'Cộng tác viên',
            link: '/cong-tac-vien',
        },
        {
            title: 'Doanh nghiệp',
            link: getUrl('/doanh-nghiep.html'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
    ],
    other: [
        {
            title: 'Chính sách bảo mật',
            link: getUrl('/chinh-sach-bao-mat.html'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Điều khoản sử dụng',
            link: getUrl('/dieu-khoan-su-dung.html'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Quy định về sử dụng sản phẩm',
            link: getUrl('/quy-dinh-ve-su-dung-san-pham.html'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Thanh toán tự động Autopay',
            link: getUrl('/dieu-khoan-thanh-toan-tu-dong-autopay.html'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Quy chế hoạt động',
            link: getUrl('/quy-che-hoat-dong.html'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
        {
            title: 'Tin tức & Báo chí',
            link: getUrl('/press.html'),
            target: '_self',
            isComing: false,
            isHandle: false,
        },
    ],
};

interface MENU {
    rel?: string;
    link: string;
    title: string;
    target: string;
    isHandle?: boolean;
    isComing?: boolean;
}

const MiddleFooter: FunctionComponent = () => {
    const { setModal } = useAskQuestion();
    const { deviceType } = useViewDetected();

    const handelClick = useCallback((event: MouseEvent<HTMLSpanElement>, option = null) => {
        if (option) {
            return;
        }

        event.preventDefault();
        setModal.askQuestion(true);
    }, []);

    const renderLink = (menu: Array<MENU>) => {
        return menu.map((item, index) => {
            const rowClassName = classnames([styles.row, { [styles.in_coming]: item.isComing }]);
            if (item.isHandle) {
                return (
                    <span key={`footer-link-${index}`} className={rowClassName} onClick={handelClick}>
                        {item.title}
                    </span>
                );
            }
            return (
                <Anchor href={item.link} target={item.target} rel={item.rel} key={`footer-link-${index}`} className={rowClassName}>
                    {item.title}
                </Anchor>
            );
        });
    };

    return (
        <div className={classnames([styles.wrapper, styles[deviceType]])}>
            <LayoutContainer className={styles.main}>
                <ViewMedia is={['mobile', 'tablet']}>
                    <Image className={styles.logo} src={'/assets/white_logo.png'} width={'92px'} height={'30px'} />
                </ViewMedia>

                <div className={classnames([styles.col_mobile, styles.col])}>
                    <ViewMedia is="desktop">
                        <Image className={styles.logo} src={'/assets/white_logo.png'} width={'92px'} height={'30px'} />
                    </ViewMedia>


                    {renderLink(middleFooter.eDoctor)}
                </div>
                <div className={styles.col}>
                    <div className={styles.title}>DỊCH VỤ</div>
                    {renderLink(middleFooter.service)}
                </div>
                <div className={styles.col}>
                    <div className={styles.title}>THÔNG TIN</div>
                    {renderLink(middleFooter.info)}
                </div>
                <div className={styles.col}>
                    <div className={styles.title}>CHO ĐỐI TÁC</div>
                    {renderLink(middleFooter.partner)}
                </div>
                <div className={styles.col}>
                    <div className={styles.title}>KHÁC</div>
                    {renderLink(middleFooter.other)}
                </div>
                <div className={styles.col}>
                    <div className={styles.connect}>KẾT NỐI VỚI CHÚNG TÔI</div>
                    <div className={styles.social_network}>
                        <Anchor href="https://www.facebook.com/edoctorvn" className={styles.icon_item}>
                            <ImgLazy src={'/assets/footer/fb_app.png'} width={'36px'} height={'36px'} />
                        </Anchor>
                        <Anchor href="https://www.youtube.com/c/eDoctorOfficial" className={styles.icon_item}>
                            <ImgLazy src={'/assets/footer/youtube.png'} width={'36px'} height={'36px'} />
                        </Anchor>
                    </div>
                    <div className={styles.download_title}>TẢI ỨNG DỤNG</div>
                    <div className={styles.download_app}>
                        <Anchor
                            href={'https://apps.apple.com/vn/app/edoctor-know-your-health/id1041327503'}
                            className={styles.icon_download}
                            tracking={{ action: 'user_click_download' }}
                        >
                            <ImgLazy
                                alt="eDoctor - App Store"
                                src={'/assets/downloadApp/app_store_black.png'}
                                width={'108px'}
                                height={'32px'}
                            />
                        </Anchor>

                        <Anchor
                            href={'https://play.google.com/store/apps/details?id=vn.edoctor.userapp'}
                            className={styles.icon_download}
                            tracking={{ action: 'user_click_download' }}
                        >
                            <ImgLazy
                                alt="eDoctor - Google Play"
                                src={'/assets/downloadApp/google_play_black.png'}
                                width={'108px'}
                                height={'32px'}
                            />
                        </Anchor>
                    </div>
                </div>
            </LayoutContainer>
        </div>
    );
};

export default MiddleFooter;
