import React, { FunctionComponent } from 'react';

// Contexts
import { withAccount } from './Contexts';

// Utilities
import dynamic from 'next/dynamic';

// Styles

const Modal = dynamic(() => import('./Components/Modal'), { ssr: false });

interface AccountProps {}

const Account: FunctionComponent<AccountProps> = (props) => {
    return (
        <>
            {props.children}

            <Modal />
        </>
    );
};

export default withAccount(Account);
