interface Analytics {
    gaMeasureID: string;
    clarityTagID: string;
    tawkToKey: string;
    branchKey: string;
    // filumKey: string;
    fbAppId: string;
}

const STAGING: Analytics = {
    branchKey: '',
    clarityTagID: '',
    gaMeasureID: 'G-JLHY53840C',
    tawkToKey: '604f0b15f7ce182709301906/1f0qc6k33',
    // filumKey: '',
    fbAppId: '',
};

const DEVELOPMENT: Analytics = {
    branchKey: '',
    clarityTagID: '',
    gaMeasureID: 'G-JJ8HZ8QZ9C',
    // gaMeasureID: 'UA-69604685-19',
    tawkToKey: '604f0b15f7ce182709301906/1f0qc6k33',
    // filumKey: '',
    fbAppId: '',
};

const PRODUCTION: Analytics = {
    fbAppId: '1040102822690853',
    gaMeasureID: 'G-R3QFVC6C9Z',
    // gaMeasureID: 'UA-69604685-10',
    clarityTagID: '5tthhoxh65',
    tawkToKey: '5954ba1d50fd5105d0c832a1/1f1cfr7bu',
    // filumKey: '',
    // branchKey: 'key_live_mfi9Uuqa4HfNLuUZMcjscelnDrel1pw8',
    branchKey: '',
};

export default ((nodeEnv: string) => {
    switch (nodeEnv) {
        case 'production':
            return PRODUCTION;
        case 'develop':
            return DEVELOPMENT;
        case 'staging':
            return STAGING;
        default:
            return PRODUCTION;
    }
})(process.env.RUN_ENV);
