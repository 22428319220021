import React, { createContext, FunctionComponent, useState, useReducer, useEffect } from 'react';

// next
import { useRouter } from 'next/router';

// page
import Modal from '@Pages/hoi-dap/Components/Asking/Modal';

// @Components
import { useLayoutContext } from '@Components/Layout/Contexts';
import { useViewDetected } from '@Components/Utils/ViewDetected';
import reducer, { initStateData } from './reducer/useReduce';

interface initStateProps {
    header: string;
    storeQuestion?: any;
    showQuestionIcon: boolean;
    formQuestionHeight: number;
    dispatch: React.Dispatch<any>;
    setHeader: (pathname: string) => void;
    toggleQuestionIcon: (status: boolean) => void;
    setFormQuestionHeight: (height: number) => void;
}

const initState: initStateProps = {
    header: '',
    formQuestionHeight: 0,
    showQuestionIcon: false,
    storeQuestion: initStateData,

    dispatch: () => null,
    setHeader: () => null,
    toggleQuestionIcon: () => null,
    setFormQuestionHeight: () => null,
};

const Context = createContext(initState);

interface UseQuestionProps {
    pageProps?: any;
    children?: React.ReactNode;
}

const UseQuestions: FunctionComponent<UseQuestionProps> = ({ children }) => {
    const { disableFooterAnswer, setDisableFooter } = useLayoutContext();
    const { deviceType } = useViewDetected();
    const isMobile = deviceType !== 'desktop';

    const [header, setHeader] = useState<string>('');
    const Router = useRouter();

    const [storeQuestion, dispatch] = useReducer(reducer, { ...initStateData });

    const [showQuestionIcon, toggleQuestionIcon] = useState<boolean>(false);
    const [formQuestionHeight, setFormQuestionHeight] = useState<number>(0);

    const handleCloseModal = (modalAskQuestion) => dispatch({ type: 'SET_MODAL_ASK_QUESTION', modalAskQuestion });

    useEffect(() => {
        if (Router.pathname === '/hoi-dap' && !isMobile && disableFooterAnswer) {
            setDisableFooter(false);
        }
    }, [Router, disableFooterAnswer]);

    const valueProvider = {
        header,
        storeQuestion,
        showQuestionIcon,
        formQuestionHeight,

        // function
        dispatch,
        setHeader,
        toggleQuestionIcon,
        setFormQuestionHeight,
    };

    return (
        <Context.Provider value={valueProvider}>
            <Modal isModalOpen={storeQuestion?.modalAskQuestion} handleCloseModal={handleCloseModal} />
            {children}
        </Context.Provider>
    );
};

// useHeader

const getData = {
    header: '',
    keyword: '',
    filterSpecialty: '',
};

type iconQuestionProps = {
    showQuestionIcon: boolean;
    formQuestionHeight: number;
    toggleQuestionIcon: (status: boolean) => void;
    setFormQuestionHeight: (height: number) => void;
};

export const useGetShowIconQuestion = (): iconQuestionProps => {
    const context = React.useContext(Context);

    if (context !== undefined) {
        const { showQuestionIcon, toggleQuestionIcon, formQuestionHeight, setFormQuestionHeight } = context || {};

        return { showQuestionIcon, toggleQuestionIcon, formQuestionHeight, setFormQuestionHeight };
    } else {
        throw new Error('storeQuestion not found');
    }
};

interface useHeaderProps {
    getData: typeof getData;
    setHeader(pathName: string): void;
}

export const useHeader = (): useHeaderProps => {
    const context = React.useContext(Context);

    if (context !== undefined) {
        const { keyword, filterSpecialty } = context?.storeQuestion || {};
        const { header, setHeader: setHeaderContext } = context;

        const setHeader = (pathName) => setHeaderContext(pathName);
        const getData = {
            header,
            keyword,
            filterSpecialty,
        };
        return { setHeader, getData };
    } else {
        throw new Error('storeQuestion not found');
    }
};

interface useAskQuestionProps {
    getModal: { askQuestion: boolean; thank: boolean; userDocument: boolean };
    setModal: {
        Thank: (modalThank: boolean) => void;
        askQuestion: (modalAskQuestion: boolean) => void;
        UserDocument: (modalUserDocument: boolean) => void;
    };
}
export const useAskQuestion = (): useAskQuestionProps => {
    const context = React.useContext(Context);
    const { dispatch, storeQuestion } = context;

    const setModal = {
        Thank: (modalThank: boolean) => dispatch({ type: 'SET_MODAL_THANK', modalThank }),
        askQuestion: (modalAskQuestion: boolean) => dispatch({ type: 'SET_MODAL_ASK_QUESTION', modalAskQuestion }),
        UserDocument: (modalUserDocument: boolean) => dispatch({ type: 'SET_MODAL_USER_DOCUMENT', modalUserDocument }),
    };

    const getModal = {
        thank: !!storeQuestion?.modalThank,
        askQuestion: !!storeQuestion?.modalAskQuestion,
        userDocument: !!storeQuestion?.modalUserDocument,
    };

    return { setModal, getModal };
};

export const useStoreNewsFeed = () => {
    const context = React.useContext(Context);
    const { storeQuestion } = context;
    const getNewsFeed = {
        keyword: storeQuestion?.keyword,
        filterSpecialty: storeQuestion?.filterSpecialty,
    };
    return { getNewsFeed };
};
// WithQuestion

export const WithQuestion = (WrappedComponent) => {
    const WithQuestion = (props) => {
        return (
            <Context.Consumer>
                {(context) => <WrappedComponent {...props} useStoreQuestion={context}></WrappedComponent>}
            </Context.Consumer>
        );
    };
    WithQuestion.WrappedComponent = WrappedComponent;
    return WithQuestion;
};

export default UseQuestions;
