import { FunctionComponent } from 'react';

// reactstrap
import { Modal as RTModal, ModalBody } from 'reactstrap';

// @Component
import Image from '@Components/Utils/Image';
import Anchor from '@Components/Utils/Anchor';
import ImgLazy from '@Components/Utils/Lazy/Image';
import { useViewDetected } from '@Components/Utils/ViewDetected';

import { useFirebaseMessaging } from '@Libs/hooks/firebase';

// NPM
import classnames from 'classnames';

// sass
import styles from './Styles.module.scss';

interface ModalProps {
    isModalOpen: boolean;
    handleCloseModal(): void;
}

const ThankYou: FunctionComponent<ModalProps> = ({ isModalOpen, handleCloseModal }) => {
    const { deviceType } = useViewDetected();

    const toggle = () => {
        useFirebaseMessaging();
        handleCloseModal();
    };

    let notiPermission;
    if (typeof Notification !== 'undefined') {
        notiPermission = Notification.permission;
    }

    const classNameThank = classnames([
        styles.modal_thank_you,
        {
            [styles.mobile]: ['mobile', 'tablet'].includes(deviceType),
        },
    ]);

    const renderContent = () => {
        return notiPermission === 'default' ? renderDefault() : renderAllowBlock();
    };

    const renderDefault = () => (
        <>
            <div className={styles.body}>
                Để nhận thông báo từ câu trả lời của bác sĩ vui lòng chọn hình thức dưới đây:
            </div>
            <div className={styles.noti}>
                Cho phép <b>eDoctor gửi thông báo</b> Hoặc <b>tải ứng dụng eDoctor</b>
            </div>
        </>
    );

    const renderAllowBlock = () => (
        <>
            <div className={styles.body}>
                Để nhận thông báo về câu trả lời của bác sĩ nhanh chóng, bạn vui lòng tải ứng dụng eDoctor
            </div>
        </>
    );

    return (
        <RTModal centered isOpen={isModalOpen} className={classNameThank} contentClassName={styles.thank_you_content}>
            <ModalBody className={styles.thank_you_body}>
                <Image
                    width="24px"
                    height="24px"
                    onClick={toggle}
                    className={styles.close}
                    src="/assets/cong_dong/ic_close.png"
                />
                <div className={styles.content}>
                    <Image src="/assets/cong_dong_v2/thank_you.png" width={'137px'} height={'84px'} />
                    <div className={styles.title}>Bạn đã đặt câu hỏi thành công</div>
                    {renderContent()}
                    <div className={styles.download_app}>
                        <Anchor
                            href={'https://apps.apple.com/vn/app/edoctor-know-your-health/id1041327503'}
                            className={styles.icon_download}
                            tracking={{ action: 'user_click_download' }}
                        >
                            <ImgLazy
                                alt="eDoctor - App Store"
                                src={'/assets/downloadApp/app_store_black.png'}
                                width={'108px'}
                                height={'32px'}
                            />
                        </Anchor>

                        <Anchor
                            href={'https://play.google.com/store/apps/details?id=vn.edoctor.userapp'}
                            className={styles.icon_download}
                            tracking={{ action: 'user_click_download' }}
                        >
                            <ImgLazy
                                alt="eDoctor - Google Play"
                                src={'/assets/downloadApp/google_play_black.png'}
                                width={'108px'}
                                height={'32px'}
                            />
                        </Anchor>
                    </div>
                    <button onClick={toggle}>Đồng ý</button>
                </div>
            </ModalBody>
        </RTModal>
    );
};

ThankYou.defaultProps = {};

export default ThankYou;
