import { useEffect, MouseEvent, useState } from 'react';

// Utilities
import 'firebase/messaging';
import uniqid from 'uniqid';
import firebase from 'firebase/app';
import { useRouter } from 'next/router';

// Config
import host from '@Config/host';

// Contexts
import { useAlertContext } from '@Components/Alert/Context';

// Utilities
import md5 from 'md5';
import Airtable from 'airtable';
import getConfig from 'next/config';
import { useAppStore } from '@Components/AppStore/Context';
import { useViewDetected } from '@Components/Utils/ViewDetected';
import { createHttp, getHttpErrorMessage, useHttpContext } from './http';

export const initializeFirebaseMessage = async (serviceWorkerRegistration?: ServiceWorkerRegistration) => {
    try {
        firebase.initializeApp({
            apiKey: process.env.FIREBASE_API_KEY,
            authDomain: process.env.FIREBASE_AUTH_DOMAIN,
            projectId: process.env.FIREBASE_PROJECT_ID,
            storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.FIREBASE_APP_ID,
        });

        const messaging = firebase.messaging();

        const currentToken = await messaging.getToken({
            ...(!!serviceWorkerRegistration && { serviceWorkerRegistration }),
        });

        return currentToken;
    } catch (error) {
        // console.error('Firebabse Initialize failed: ', error);
    }
};

export const registerServiceWorker = (callback?: (registration: ServiceWorkerRegistration) => void) => {
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker.register('/sw.js').then(
                    (registration) => {
                        // console.log('Service worker registration success.');

                        if (callback) {
                            callback(registration);
                        }
                    },
                    () => {
                        // console.error('Service worker registration failed.', error);
                    },
                );
            });
        }
    }, []);
};

export const getFingerprintId = async () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    // const FingerprintJS = require('@fingerprintjs/fingerprintjs');
    // const fp = await FingerprintJS.load();
    // const result = await fp.get();
    // return result.visitorId;
};

export const assetMedicalSpecialty = (code: string): string => {
    return `/assets/chuyen_khoa/${code}.png`;
};

export const toCurrency = (amount: number, isUnit?: boolean): string => {
    const price = amount || 0;
    const unit = isUnit ? 'đ' : '';
    return `${price.toLocaleString('vi').replace(/\d(?=(\d{3})+\.)/g, '$&.')}${unit}`;
};

export const getAttachmentAvatar = (userId: string, userType: string, type = 'user_avatar'): string => {
    return `${host.uploadDomain}/${type}/${userType}/${userId}`;
};

export const getAttachmentImg = (attachmentId: string, width?: number): string => {
    return `${host.uploadDomain}/image/${attachmentId}${width ? `?width=${width}` : ''}`;
};

export const getUrl = (url: string, domain: string = host.homeDomain): string => {
    if (!url) return '#';

    return `${domain}${url}`;
};

export const handleOpenApp = (applink: string) => {
    return (event: MouseEvent<HTMLElement>): void => {
        event.preventDefault();

        window.location.replace(applink);

        setTimeout(() => {
            window.location.replace('https://dl.edoctor.io/taiapp');
        }, 1000);
    };
};

export const openApp = (applink: string): void => {
    const { isLoaded } = useAppStore();
    const { deviceType } = useViewDetected();

    useEffect(() => {
        if (deviceType !== 'desktop' && isLoaded) {
            window.location.replace(applink);
        }
    }, [isLoaded]);
};

export const handleOpenNewTab = (event: MouseEvent<HTMLAnchorElement>, isApp: boolean): void => {
    if (isApp) {
        event.preventDefault();

        const { href } = event.currentTarget;

        window.postMessage(
            JSON.stringify({
                href: href,
                type: 'OPEN_NEW_TAB',
            }),
            window.location.origin,
        );
    }
};

let airTable;
const BASE_ID = 'appWC6gpnBTi1MQed';
export const MESSAGE_ERROR = 'Đã có lỗi xảy ra! Vui lòng thử lại hoặc liên hệ đến đội ngũ phát triển.';
export const MESSAGE_SUCCESS = 'Cảm ơn bạn đã cung cấp thông tin! eDoctor sẽ liên hệ trong thời gian sớm nhất';

export interface CallbackDataType {
    data: any;
    type: 'success' | 'error';
}

export const useSupportAirTable = (baseId: string = BASE_ID) => {
    const router = useRouter();
    const { alert } = useAlertContext();
    const { publicRuntimeConfig } = getConfig();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        try {
            airTable = new Airtable({ apiKey: publicRuntimeConfig.airtableApiKey });
        } catch (error) {
            alert({
                type: 'error',
                title: 'Đã xảy ra lỗi',
                text: 'Đã có lỗi xảy ra! Vui lòng thử lại hoặc liên hệ đến đội ngũ phát triển.',
            });
        }
    }, []);

    const sendSupport = (phoneNumber: string, fullName?: '', callback = null) => {
        setLoading(true);
        try {
            airTable
                .base(baseId)('support')
                .create(
                    [
                        {
                            fields: {
                                fullname: fullName,
                                page: router.asPath,
                                phonenumber: phoneNumber,
                            },
                        },
                    ],
                    function (err) {
                        setLoading(false);

                        if (err) {
                            alert({
                                type: 'error',
                                title: 'Đã xảy ra lỗi',
                                text: MESSAGE_ERROR,
                            });
                            return;
                        }
                        alert({
                            type: 'success',
                            title: 'Gửi thành công',
                            text: MESSAGE_SUCCESS,
                        });

                        if (callback) {
                            callback(err);
                        }
                    },
                );
        } catch (error) {
            setLoading(false);
            alert({
                type: 'error',
                title: 'Đã xảy ra lỗi',
                text: MESSAGE_ERROR,
            });
        }
    };

    const sendAirTable = (
        baseForm: string,
        fields: { [key: string]: any },
        messageSuccess: string = MESSAGE_SUCCESS,
        callback: (data: CallbackDataType) => void = null,
    ) => {
        setLoading(true);
        try {
            airTable
                .base(baseId)(baseForm)
                .create(
                    [
                        {
                            fields,
                        },
                    ],
                    function (err) {
                        setLoading(false);

                        if (err) {
                            if (callback) {
                                callback({
                                    type: 'error',
                                    data: true,
                                });
                            } else {
                                alert({
                                    type: 'error',
                                    text: MESSAGE_ERROR,
                                    title: 'Đã xảy ra lỗi',
                                });
                            }

                            return;
                        }

                        if (callback) {
                            callback({
                                type: 'success',
                                data: true,
                            });
                        } else {
                            alert({
                                type: 'success',
                                text: messageSuccess,
                                title: 'Gửi thành công',
                            });
                        }
                    },
                );
        } catch (error) {
            setLoading(false);
            alert({
                type: 'error',
                title: 'Đã xảy ra lỗi',
                text: MESSAGE_ERROR,
            });
        }
    };

    return { loading, sendSupport, sendAirTable };
};

export const parsePagination = (page: number, limit: number): { _start: number; _limit: number } => {
    const _start = Number((page - 1) * limit);

    return {
        _start,
        _limit: limit,
    };
};

interface UploadOptions {
    url?: string;
    type: string;
}

interface UploadAttachment {
    id?: string;
    file?: File;
    url?: string;
    base64?: string;
    isError?: boolean;
    isUploaded?: boolean;
    errorMessage?: string;
    isUploading?: boolean;
    attachmentId?: string;
}

interface UploadAttachments {
    [key: string]: UploadAttachment;
}

interface UploadReturn {
    attachments: UploadAttachments;

    remove: (id: string) => void;
    upload: (files: FileList) => void;
}

export const useUpload = (options?: UploadOptions): UploadReturn => {
    const { token } = useHttpContext();
    const [attachments, setAttachments] = useState<UploadAttachments>({});

    const config = { url: host.uploadAPI, ...options };
    const uploadHttp = createHttp(token, config.url);

    const uploadAttachment = async (attachment: UploadAttachment) => {
        const { id, file } = attachment;

        try {
            const response = await uploadHttp.post('attachment', file, {
                headers: {
                    'e-file-name': md5(file.name),
                    'Content-Type': file.type,
                    'e-file-size': file.size,
                    'e-type': config.type,
                },
            });

            const { attachmentId } = response.data;
            patchAttachment(id, {
                attachmentId,
                isUploading: false,
                isUploaded: true,
                url: `${host.uploadDomain}/image/${attachmentId}`,
            });
        } catch (error) {
            const errorMessage = getHttpErrorMessage(error);
            patchAttachment(id, { isError: true, errorMessage });
        }
    };

    const patchAttachment = (id, attachment: UploadAttachment) => {
        setAttachments((currentAttachments) => ({
            ...currentAttachments,
            [id]: {
                ...currentAttachments[id],
                ...attachment,
            },
        }));
    };

    const parseToBase64 = (file: File, callback?: (base64: any) => void) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            if (callback) {
                callback(event.target.result);
            }
        };

        reader.readAsDataURL(file);
    };

    const upload = async (files: FileList) => {
        const attachments: UploadAttachments = {};

        for (let index = 0; index < files.length; index++) {
            const file = files[index];
            const id = `tmp_${uniqid()}`;
            const attachment = {
                id,
                file,
                url: null,
                base64: null,
                isError: false,
                isUploaded: false,
                isUploading: true,
                attachmentId: null,
            };

            attachments[id] = attachment;
            uploadAttachment(attachment);
            parseToBase64(files[index], (base64) => patchAttachment(id, { base64, url: base64 }));
        }

        setAttachments((currentAttachments) => ({ ...currentAttachments, ...attachments }));
    };

    const remove = (id: string) => {
        const currentAttachments = { ...attachments };
        delete currentAttachments[id];

        setAttachments(currentAttachments);
    };

    return {
        upload,
        remove,
        attachments,
    };
};

export const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const formatCurrency = (value = 0, locale = 'vi-VI', currencyType = 'VND'): string => {
    return value.toLocaleString(locale, { style: 'currency', currency: currencyType }).replace('₫', 'đ');
};

export const uuidV4 = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (character) => {
        const random = Math.floor(Math.random() * 16);

        const v = character === 'x' ? random : (random && 0x3) || 0x8;

        return v.toString(16);
    });
};

