import React, { FunctionComponent, useEffect } from 'react';

// Components
import Footer from './Components/Footer';
import Header from './Components/Header';
import Loading from './Components/Loading';
// import ComponentLazy from '@Components/Utils/Lazy/Component';
// import Actions from './Components/Actions';

// Styles
import styles from './Layout.module.scss';

// Utilities
import dynamic from 'next/dynamic';
import classnames from 'classnames';

// Contexts
import App from '@Components/Utils/App';
import { useLayoutContext, withLayout } from './Contexts';
import { useAppStore } from '@Components/AppStore/Context';
import { useViewDetected } from '@Components/Utils/ViewDetected';

// const Header = dynamic(() => import('./Components/Header'));
// const Footer = dynamic(() => import('./Components/Footer'), { ssr: false });
// const Actions = dynamic(() => import('./Components/Actions'), { ssr: false });
const QuickSupport = dynamic(() => import('./Components/QuickSupport'), { ssr: false });
interface LayoutProps {
    disableHeader?: boolean;
    disableFooter?: boolean;
}

const Layout: FunctionComponent<LayoutProps> = (props) => {
    const { isApp } = useAppStore();
    const { deviceType } = useViewDetected();
    const { disableFooter, disableHeader, disableFooterAnswer } = useLayoutContext(
        props.disableFooter,
        props.disableHeader,
    );

    const className = classnames([
        styles.layout,
        styles[deviceType],
        {
            [styles.disable_header]: props.disableHeader,
        },
    ]);

    useEffect(() => {
        const handleFocus = (event: any) => {
            if (deviceType !== 'desktop' && isApp) {
                if (
                    (event?.target?.tagName === 'INPUT' &&
                        !['radio', 'checkbox'].includes(event?.target.getAttribute('type'))) ||
                    event?.target?.tagName === 'TEXTAREA'
                ) {
                    window.document.body.style.paddingBottom = '320px';
                }
            }
        };

        const handleBlur = (event: any) => {
            if (deviceType !== 'desktop' && isApp) {
                if (
                    (event?.target?.tagName === 'INPUT' &&
                        !['radio', 'checkbox'].includes(event?.target.getAttribute('type'))) ||
                    event?.target?.tagName === 'TEXTAREA'
                ) {
                    window.document.body.style.paddingBottom = '0px';
                }
            }
        };

        window.document.addEventListener('blur', handleBlur, true);
        window.document.addEventListener('focus', handleFocus, true);

        return () => {
            window.document.removeEventListener('focus', handleFocus);
            window.document.removeEventListener('blur', handleBlur);
        };
    }, []);

    return (
        <div className={className}>
            <Loading />

            {/* <App is={false}>
                <ComponentLazy
                    dynamic={() => import('./Components/RouterFeedback')}
                    placeholder={() => <div style={{ height: '64px' }} />}
                />
            </App> */}

            {!disableHeader && <Header />}

            {props.children}

            {!disableFooter && <Footer />}

            {/* an hien footer trang cong dog */}
            {disableFooterAnswer && <Footer />}

            {/* <Actions /> */}
            <App is={false}>
                <QuickSupport />
            </App>
        </div>
    );
};

Layout.defaultProps = {
    disableHeader: false,
    disableFooter: false,
};

export default withLayout<LayoutProps>(Layout);
