import { AxiosInstance } from 'axios';

import md5 from 'md5';

const uploadApi = (http: AxiosInstance) => {
    return {
        getAttachmentImage: async (file: File, type) => {
            const response: any = await http.post('attachment', file, {
                headers: {
                    'e-file-name': md5(file.name),
                    'Content-Type': file.type,
                    'e-file-size': file.size,
                    'e-type': type,
                },
            });
            return response.data;
        },
        generateTemporaryId: async () => {
            const response = await http.get('/feedback_upload/generate_id');
            return response.data;
        },
        uploadTemporaryImage: async (fileId: string, file: File, type = 'feedback_upload') => {
            const response: any = await http.post(`/feedback_upload/${fileId}`, file, {
                headers: {
                    'e-file-name': md5(file.name),
                    'Content-Type': file.type,
                    'e-file-size': file.size,
                    'e-type': type,
                },
            });
            return response.data;
        },
    };
};
export default uploadApi;
