import { FunctionComponent } from 'react';

// Components
import Page from '@Components/Utils/Page';
import TopFooter from './Components/TopFooter';
import MiddleFooter from './Components/MiddleFooter';
import BottomFooter from './Components/BottomFooter';

// Styles
import styles from './Footer.module.scss';

const Footer: FunctionComponent = () => {
    return (
        <footer className={styles.wrapper}>
            <Page>
                <TopFooter />
                <MiddleFooter />
                <BottomFooter />
            </Page>
        </footer>
    );
};

export default Footer;
