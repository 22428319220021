import { useHttp } from '@Libs/http';
import { AxiosInstance } from 'axios';
import { ConfigInterface, responseInterface } from 'swr';

interface payloadProps {
    profileId: string;
    attachmentIds: [];
    questionContent: string;
    agreeShareAttachment: boolean;
    approveShareAttachment: boolean;
}

interface payloadRateProps {
    ratePoint: number;
    rateComment: string;
    questionCommentId: string;
}

const questionApi = (http: AxiosInstance) => {
    return {
        newQuestion: async (payload: payloadProps) => {
            return await http.post('/question/new_question', {
                profileId: '',
                attachmentIds: [],
                questionContent: '',
                agreeShareAttachment: false,
                approveShareAttachment: false,
                ...payload,
            });
        },
        details: async (questionId: string) => {
            return await http.get(`/question/${questionId}`, {});
        },
        thanks: async (questionCommentId: string) => {
            return await http.post(`/question/comment/${questionCommentId}/thanks`, {});
        },
        bookmark: async (questionId: string) => {
            return await http.post(`/question/${questionId}/bookmark`, {});
        },
        unBookmark: async (questionId: string) => {
            return await http.post(`/question/${questionId}/un_bookmark`);
        },
        myQuestions: async (queries = {}) => {
            const response = await http.get('/question/my_questions', {
                params: { limit: 10, reviewStatus: 'all', ...queries },
            });
            return response.data;
        },
        ///
        rate: async (payload: payloadRateProps) => {
            const { questionCommentId, ratePoint, rateComment } = payload;

            return await http.post(`question/comment/${questionCommentId}/rate`, {
                ratePoint,
                rateComment,
            });
        },
    };
};

const MY_QUESTION_KEY = 'my_question';
export const getMyQuestion = (
    queries?: { [key: string]: any },
    config?: ConfigInterface,
    key = MY_QUESTION_KEY,
   
): responseInterface<any, any> => {
    if (queries?.Authorization) {
        return useHttp(key, async ({ useHttp }) => await questionApi(useHttp).myQuestions(queries), config);
    }
    return;
};

export default questionApi;
