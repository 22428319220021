interface Firebase {
    appId: string;
    apiKey: string;
    projectId: string;
    authDomain: string;
    databaseURL: string;
    measurementId: string;
    storageBucket: string;
    messagingSenderId: string;
}

const DEVELOPMENT: Firebase = {
    projectId: 'edoctor-test',
    measurementId: 'G-N8PN1LSBHM',
    messagingSenderId: '219790567873',
    storageBucket: 'edoctor-test.appspot.com',
    authDomain: 'edoctor-test.firebaseapp.com',
    appId: '1:219790567873:web:ff1120b0ff4ff3d687e740',
    apiKey: 'AIzaSyCJQmcEquJNf0owOEnMfi4jOQ5EHfQWFAo',
    databaseURL: 'https://edoctor-test.firebaseio.com',
};

const STAGING: Firebase = {
    projectId: 'edoctor-test',
    measurementId: 'G-N8PN1LSBHM',
    messagingSenderId: '219790567873',
    storageBucket: 'edoctor-test.appspot.com',
    authDomain: 'edoctor-test.firebaseapp.com',
    appId: '1:219790567873:web:ff1120b0ff4ff3d687e740',
    apiKey: 'AIzaSyCJQmcEquJNf0owOEnMfi4jOQ5EHfQWFAo',
    databaseURL: 'https://edoctor-test.firebaseio.com',
};

const PRODUCTION: Firebase = {
    measurementId: 'G-6W011BMFW9',
    projectId: 'edoctor-user-app',
    messagingSenderId: '643601784950',
    storageBucket: 'edoctor-user-app.appspot.com',
    authDomain: 'edoctor-user-app.firebaseapp.com',
    apiKey: 'AIzaSyCY4y51li1fm2SIbkQ7drlcfbN1-AcSGM8',
    appId: '1:643601784950:web:5c1c8769ed665d3dacff57',
    databaseURL: 'https://edoctor-user-app.firebaseio.com',
};

export default ((nodeEnv: string) => {
    switch (nodeEnv) {
        case 'production':
            return PRODUCTION;
        case 'develop':
            return DEVELOPMENT;
        case 'staging':
            return STAGING;
        default:
            return PRODUCTION;
    }
})(process.env.RUN_ENV);
