import { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';

// Components

// Apis
import { useInitData } from '@Libs/api/user/default';
import { useCountNotification } from '@Libs/api/user/notification';
import { useMedicalSpecialties } from '@Libs/api/cms/medicalSpecialties';

interface MedicalSpecialty {
    id: string;
    name: string;
    code: string;
    class: string;
}

interface NotificationItem {
    total?: number;
    article?: number;
    question?: number;
    promotion?: number;
    [key: string]: number;
}

interface AppStoreContextType {
    isLoaded: boolean;

    allMedicalSpecialties?: {
        [code: string]: MedicalSpecialty;
    };
    countNotification?: NotificationItem;
    updateCountNotification: () => void;

    loading: boolean;
    isApp: boolean;
    setLoading: (loading: boolean) => void;
}

const initState: AppStoreContextType = {
    isLoaded: false,
    countNotification: {},
    allMedicalSpecialties: {},
    updateCountNotification: () => null,

    loading: false,
    isApp: false,
    setLoading: () => null,
};

const AppStoreContext = createContext(initState);

export const useAppStore = () => useContext(AppStoreContext);

interface ProviderProps {
    isApp: boolean;
}

export const Provider: FunctionComponent<ProviderProps> = ({ isApp, ...props }) => {
    const { data: initData = {} } = useInitData();
    const { data: cmsMedicalSpecialties = [] } = useMedicalSpecialties();
    const { data: countNotificationData = {}, mutate: updateCountNotification } = useCountNotification([
        'question',
        'article',
        'promotion',
        'service_booking',
    ]);
    const medicalSpecialtyByKey = cmsMedicalSpecialties.reduce((medicalSpecialtyByKey: any, medicalSpecialy: any) => {
        medicalSpecialtyByKey[medicalSpecialy.code] = { ...medicalSpecialy };
        return medicalSpecialtyByKey;
    }, {});

    const initAllMedicalSpecialties = initData.allMedicalSpecialties || [];
    const allMedicalSpecialties = initAllMedicalSpecialties.reduce(
        (allMedicalSpecialties, medicalSpecialy: MedicalSpecialty) => {
            allMedicalSpecialties[medicalSpecialy.code] = {
                ...medicalSpecialy,
                ...medicalSpecialtyByKey[medicalSpecialy.code],
            };

            return allMedicalSpecialties;
        },
        {},
    );

    const countNotification: NotificationItem = {
        ...countNotificationData,
        total: Object.keys(countNotificationData).reduce((total, item) => {
            return total + countNotificationData[item];
        }, 0),
    };

    const [isLoaded, setLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('load', () => {
            setLoaded(true);

            try {
                if (window.performance) {
                    const timeSincePageLoad = Math.round(performance.now());
                    gtag('event', 'timing_complete', {
                        name: 'load',
                        value: timeSincePageLoad,
                        event_category: 'Performance',
                    });
                }
            } catch (error) {
                if (process.env.RUN_ENV === 'develop') {
                    // eslint-disable-next-line no-console
                    console.error(error);
                }
            }
        });
    }, []);

    return (
        <AppStoreContext.Provider
            value={{
                allMedicalSpecialties,
                countNotification,
                updateCountNotification,
                loading,
                isApp,
                setLoading,
                isLoaded,
            }}
        >
            {props.children}
        </AppStoreContext.Provider>
    );
};

export function withAppStore<P>(WrappedComponent: FunctionComponent): FunctionComponent<P & ProviderProps> {
    return ({ isApp, ...props }) => (
        <Provider isApp={isApp}>
            <WrappedComponent {...props} />
        </Provider>
    );
}
