import { AxiosInstance } from 'axios';

interface signInSmsRequestPayload {
    phoneNumber: string;
    referenceCode?: string;
}

interface signInSmsVerifyPayload {
    pinNumber: string;
    phoneNumber: string;
}
const authApi = (http: AxiosInstance) => {
    return {
        signIn: async ({ identifier, password }) => {
            const response = await http.post('auth/local', { identifier, password });
            return response.data;
        },
        register: async ({ email, username, password }) => {
            const response = await http.post('auth/local/register', {
                email,
                username,
                password,
            });
            return response.data;
        },
        signInSmsRequest: async (payload: signInSmsRequestPayload) => {
            return await http.post('auth/sign_in_sms_request', payload);
        },
        signInSmsVerify: async (payload: signInSmsVerifyPayload) => {
            const response: any = await http.post('auth/sign_in_sms_verify', payload);
            return response.data;
        },
        signOut: async () => {
            return await http.post('auth/sign_out', {});
        },
        signInFB: async (accessToken: string) => {
            const response: any = await http.post('auth/sign_in_facebook', { accessToken });
            return response.data || {};
        },
        signInGoogle: async (platform: string, idToken: string) => {
            const response: any = await http.post('auth/sign_in_google', { platform, idToken });
            return response.data || {};
        },
    };
};

export default authApi;
