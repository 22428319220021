import React, { FunctionComponent, memo, useEffect, useState } from 'react';

// Components
import Image from '@Components/Utils/Image';
// import Actions from './Components/Actions';

// Styles
import styles from '../../Layout.module.scss';

// Utilities
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { useAppStore } from '@Components/AppStore/Context';

// Contexts
interface LoadingProps {
    loading?: boolean;
}

const Loading: FunctionComponent<LoadingProps> = (props) => {
    const router = useRouter();
    const { loading: appLoading } = useAppStore();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        setLoading(appLoading);
    }, [appLoading]);

    useEffect(() => {
        const handleRouteChangeStart = () => {
            setLoading(true);
            document.body.classList.add('overflow-hidden');
            document.querySelector('html').classList.add('overflow-hidden');
        };
        const handleRouteChangeComplete = () => {
            setLoading(false);
            document.body.classList.remove('overflow-hidden');
            document.querySelector('html').classList.remove('overflow-hidden');
        };

        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeError', handleRouteChangeComplete);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeError', handleRouteChangeComplete);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, []);

    const loadingClassName = classnames([
        styles.loading,
        {
            [styles.active]: loading,
        },
    ]);

    return (
        <div className={loadingClassName}>
            <Image width="25px" height="25px" src={'/assets/loading.png'} />
        </div>
    );
};

export default memo<LoadingProps>(Loading);
