import { FunctionComponent } from 'react';

// Utilities
import { useAppStore } from '@Components/AppStore/Context';

interface AppProps {
    is?: false | true;
    className?: string;
}

const App: FunctionComponent<AppProps> = (props) => {
    const { isApp } = useAppStore();

    if (isApp === props.is) {
        return <>{props.children}</>;
    }

    return null;
};
export default App;

App.defaultProps = {
    is: true,
};
