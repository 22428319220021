import { AxiosInstance } from 'axios';
import { useHttp } from '@Libs/http';
import { responseInterface } from 'swr';

const accountApi = (http: AxiosInstance) => ({
    getSummary: async () => {
        const response = await http.get('/account/summary');
        return response.data;
    },
});

const SUMMARY_KEY = 'summary_search';
export const useSummary = (key = SUMMARY_KEY): responseInterface<any, any> =>
    useHttp(key, async ({ userHttp }) => await accountApi(userHttp).getSummary(), {
        auth: true,
    });

export default accountApi;
