import { useCallback } from 'react';
import App, { AppContext } from 'next/app';

import Router from 'next/router';

// Components
import Head from 'next/head';
import Alert from '@Components/Alert';
import Layout from '@Components/Layout';
import { HttpWrapper } from '@Libs/http';
import { CorporateContactJsonLd, NextSeo } from 'next-seo';
// 3rds
import LazyloadScripts from '@Components/Utils/Lazy/Script';

// Utilitiess

import Cookie from 'cookie';
import MobileDetect from 'mobile-detect';
import Account from '@Components/Account';
import { registerServiceWorker } from '@Libs/utils';

// Contexts
import { Provider as AppStore } from '@Components/AppStore/Context';
import ViewDetected, { getDeviceType } from '@Components/Utils/ViewDetected';
import UseQuestions from './hoi-dap/Context';

// Styles
import '@Styles/globals.scss';

// Config
import seo from '@Config/seo';
import host from '@Config/host';
import analytics from '@Config/analytics';

function MyApp({ Component, pageProps, deviceType, disableFooter, disableHeader, token, isApp, pathname }) {
    registerServiceWorker();

    const handleHttpError = useCallback((error: Error) => {
        // eslint-disable-next-line no-console
        console.error('handleHttpError', error);
    }, []);

    return (
        <>
            <Head>
                <meta name="copyright" content="eDoctor" />
                <meta name="theme-color" content="#248eff" />
                <meta name="distribution" content="Global" />
                <meta name="format-detection" content="telephone=no" />
                <meta name="viewport" content="width=device-width,minimum-scale=1,initial-scale=1" />
                <meta name="keywords" content="eDoctor, Sức khoẻ, Nhà thuốc, theo dõi sức khỏe, cải thiện sức khỏe" />

                <link rel={'manifest'} href={'/manifest.json'} />
                <link rel="apple-touch-icon" href="/assets/icon_2x.png"></link>
                <link rel="icon" href="/favicon.ico" type="image/gif" sizes="16x16" />

                <link rel="preconnect" href={host.cmsApi} crossOrigin="" />
                <link rel="preconnect" href={host.uploadDomain} crossOrigin="" />
                <link rel="dns-prefetch" href={host.cmsApi} />
                <link rel="dns-prefetch" href={host.uploadDomain} />

                {/* FONTS */}
                <link
                    as={'font'}
                    type="font/woff2"
                    rel={'preload'}
                    href={'/fonts/SFProDisplay-Bold.woff2'}
                    crossOrigin=""
                />
                <link
                    as={'font'}
                    type="font/woff2"
                    rel={'preload'}
                    href={'/fonts/SFProDisplay-Regular.woff2'}
                    crossOrigin=""
                />
                <link
                    as={'font'}
                    type="font/woff2"
                    rel={'preload'}
                    href={'/fonts/SFProDisplay-Semibold.woff2'}
                    crossOrigin=""
                />

                {/* ANIMATE CSS */}
                <link
                    as={'style'}
                    rel="preload"
                    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                />
                <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
                />

                {/* JSON LD */}
                <script
                    type="application/ld+json"
                    dangerouslySetInnerHTML={{ __html: JSON.stringify(seo.jsonLD.website) }}
                />

                {/* OPEN GRAPH */}
                <meta property="fb:app_id" content={analytics.fbAppId} />

                {/* Lazyloaded Scripts */}
                <LazyloadScripts />
            </Head>

            <style jsx global>{`
                @font-face {
                    font-display: swap;
                    font-family: 'SFProDisplay';
                    src: url('/fonts/SFProDisplay-Regular.woff2') format('opentype');
                }

                @font-face {
                    font-weight: 500;
                    font-display: swap;
                    font-family: 'SFProDisplay';
                    src: url('/fonts/SFProDisplay-Regular.woff2') format('opentype');
                }

                @font-face {
                    font-weight: 600;
                    font-display: swap;
                    font-family: 'SFProDisplay';
                    src: url('/fonts/SFProDisplay-Semibold.woff2') format('opentype');
                }

                @font-face {
                    font-weight: bold;
                    font-display: swap;
                    font-family: 'SFProDisplay';
                    src: url('/fonts/SFProDisplay-Bold.woff2') format('opentype');
                }

                html {
                    box-sizing: border-box;
                    font-size: 14px;
                }

                *,
                *:before,
                *:after {
                    box-sizing: inherit;
                }

                body,
                h1,
                h2,
                h3,
                h4,
                h5,
                h6,
                p,
                ol,
                ul {
                    margin: 0;
                    padding: 0;
                    font-weight: normal;
                }

                ol,
                ul {
                    list-style: none;
                }

                body {
                    font-size: 14px;
                    color: #000000;
                    font-family: 'SFProDisplay', sans-serif !important;
                    font-display: optional;
                }
            `}</style>

            <CorporateContactJsonLd {...seo.jsonLD.contact} />
            <NextSeo {...seo.basic(pathname, pageProps)} key={pathname} />

            <Alert>
                <HttpWrapper token={token} onError={handleHttpError}>
                    <AppStore isApp={isApp}>
                        <ViewDetected deviceType={deviceType}>
                            <Account token={token}>
                                <UseQuestions>
                                    <Layout disableFooter={disableFooter} disableHeader={disableHeader}>
                                        <Component {...pageProps} />
                                    </Layout>
                                </UseQuestions>
                            </Account>
                        </ViewDetected>
                    </AppStore>
                </HttpWrapper>
            </Alert>
        </>
    );
}

const handleUTMTracking = (appContext: AppContext) => {
    const query = appContext.ctx.query;
    const trackingKeys = [
        'traffic_id',
        'aff_sid',
        'utm_source',
        'utm_medium',
        'utm_term',
        'utm_content',
        'utm_campaign',
        'source',
        'extraData',
        'internal_channel',
        'url_referral',
        'channel',
        'referral',
    ];
    for (const key in query) {
        if (trackingKeys.includes(key)) {
            appContext.ctx.res.setHeader(
                'Set-Cookie',
                Cookie.serialize(key, query[key].toString(), {
                    maxAge: 60 * 60 * 24 * 7, // 1 week,
                    domain: host.rootDomain,
                }),
            );
        }
    }
};

const addIsAppCookies = (appContext: AppContext) => {
    if (appContext?.ctx?.query?.isApp === 'true') {
        appContext.ctx.res.setHeader(
            'Set-Cookie',
            Cookie.serialize('isApp', 'true', {
                maxAge: 60 * 60 * 24 * 365, // 1 years,
            }),
        );
    }
};

const getCookieToken = (appContext: AppContext) => {
    const cookies = Cookie.parse(appContext.ctx?.req?.headers?.cookie || '');
    return cookies.token || null;
};

const getCookieIsApp = (appContext: AppContext) => {
    const userDetected = new MobileDetect(appContext.ctx.req.headers['user-agent']);
    const deviceType = getDeviceType(userDetected);
    const cookies = Cookie.parse(appContext.ctx.req.headers.cookie || '');
    return (cookies.isApp === 'true' || appContext?.ctx?.query?.isApp === 'true') && deviceType !== 'desktop';
};

const getLayoutConfiguration = (appContext: AppContext) => {
    const userDetected = new MobileDetect(appContext.ctx.req.headers['user-agent']);
    const deviceType = getDeviceType(userDetected);

    let disableFooter = false;
    let disableHeader = false;

    if (appContext.ctx.pathname.includes('thong-bao') && deviceType === 'mobile') {
        disableFooter = true;
        disableHeader = true;
    }

    if (['desktop'].includes(deviceType) && appContext.ctx.pathname === '/hoi-dap') {
        disableFooter = true;
    }

    const isFeedback = appContext.ctx.pathname.includes('/gop-y');
    const isDiseasePage = appContext.ctx.pathname.includes('/tra-cuu/benh');
    const isDoctorPage = appContext.ctx.pathname.includes('/bac-si');
    const isPostsPage = appContext.ctx.pathname.includes('/bai-viet');
    const isClinicsPage = appContext.ctx.pathname.includes('/tra-cuu/phong-kham');
    const isNotificationPage = appContext.ctx.pathname.includes('/thong-bao');
    const isCollaborator = appContext.ctx.pathname.includes('/cong-tac-vien');
    const isHomeTest = appContext.ctx.pathname.includes('/xet-nghiem-tai-nha');
    const isSearchDoctorPage = appContext.ctx.pathname.includes('/tra-cuu/bac-si');
    const isCommunicationPages = appContext.ctx.pathname.indexOf('/hoi-dap') !== -1;
    const isPolicy = appContext.ctx.pathname.includes('/chinh-sach');

    const isCommunicationSupport = appContext.ctx.pathname.indexOf('/tu-van-suc-khoe') !== -1;
    const isSupportPage = appContext.ctx.pathname.indexOf('/ho-tro') !== -1;
    // const isGeneralExamination = appContext.ctx.pathname.includes('/kham-tong-quat');
    // const isHomeTest = appContext.ctx.pathname.includes('/xet-nghiem-tai-nha');
    const isGeneralExamination = appContext.ctx.pathname.includes('/kham-tong-quat');
    const isGenTest = appContext.ctx.pathname.includes('/xet-nghiem-gen');
    const isCancerScreening = appContext.ctx.pathname.includes('/tam-soat-ung-thu');

    if (
        ['mobile', 'tablet'].includes(deviceType) &&
        (isFeedback ||
            isPolicy ||
            isGenTest ||
            isHomeTest ||
            isFeedback ||
            isPostsPage ||
            isDoctorPage ||
            isDiseasePage ||
            isClinicsPage ||
            isCollaborator ||
            isNotificationPage ||
            isCancerScreening ||
            isSearchDoctorPage ||
            isSearchDoctorPage ||
            isCommunicationPages ||
            isGeneralExamination ||
            isCommunicationSupport ||
            // isGeneralExamination ||
            isSupportPage)
    ) {
        disableFooter = true;
    }
    const cookies = Cookie.parse(appContext.ctx.req.headers.cookie || '');
    if (deviceType === 'mobile' && (appContext?.ctx?.query?.isApp === 'true' || cookies.isApp === 'true')) {
        disableFooter = true;
        disableHeader = true;
    }

    return {
        disableFooter,
        disableHeader,
    };
};

initRouterListeners();
function initRouterListeners() {
    const onRouteChangeStart = () => {
        saveScrollPos(Router.asPath);
    };

    function onRouteChangeComplete(url) {
        restoreScrollPos(url);
    }

    function saveScrollPos(url) {
        if (Router?.route !== '/hoi-dap/[id]' && sessionStorage.getItem('hoiDap')) {
            sessionStorage.removeItem('hoiDap');
        }

        if (Router?.route === '/hoi-dap') {
            const scrollPos = { x: window.scrollX, y: window.scrollY, url };
            sessionStorage.setItem('hoiDap', JSON.stringify(scrollPos));
        }
    }

    function restoreScrollPos(url) {
        const scrollPos = JSON.parse(sessionStorage.getItem('hoiDap'));

        if (scrollPos && scrollPos.url === url) {
            window.requestAnimationFrame(() =>
                window.scrollTo({ top: scrollPos.y, left: scrollPos.x, behavior: 'smooth' }),
            );
        }
    }

    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
}

MyApp.getInitialProps = async (appContext: AppContext) => {
    const appProps = await App.getInitialProps(appContext);

    handleUTMTracking(appContext);

    addIsAppCookies(appContext);

    const token = getCookieToken(appContext);
    const isApp = getCookieIsApp(appContext);

    const userDetected = new MobileDetect(appContext.ctx.req.headers['user-agent']);
    const deviceType = getDeviceType(userDetected);

    const layoutConfiguration = getLayoutConfiguration(appContext);

    const pathname = appContext.ctx.asPath || appContext.ctx.pathname;
    return { ...appProps, ...layoutConfiguration, token, isApp, deviceType, pathname };
};

export default MyApp;
