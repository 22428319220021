interface Hosts {
    cmsApi: string;
    domain: string;
    userApi: string;
    doctorApi: string;
    uploadAPI: string;
    homeDomain: string;
    rootDomain: string;
    uploadDomain: string;
    epharmacyDomain: string;
}

const DEVELOPMENT: Hosts = {
    cmsApi: 'https://cms.e-doctor.dev',
    userApi: 'https://user.api.e-doctor.dev/v1',
    doctorApi: 'https://doctor.api.e-doctor.dev/v1',

    rootDomain: 'e-doctor.dev',
    homeDomain: 'https://e-doctor.dev',
    domain: 'https://beta.e-doctor.dev',
    uploadAPI: 'https://upload.e-doctor.dev',
    uploadDomain: 'https://e-doctor.dev/_upload',
    epharmacyDomain: 'https://epharmacy.e-doctor.dev',
};

const STAGING: Hosts = {
    userApi: 'https://user.api.edoctor-demo.com/v1',
    doctorApi: 'https://doctor.api.edoctor-demo.com/v1',
    cmsApi: 'https://cms.edoctor-demo.com',

    rootDomain: 'edoctor-demo.com',
    homeDomain: 'https://edoctor-demo.com',
    domain: 'https://beta.edoctor-demo.com',
    uploadAPI: 'https://upload.e-doctor.com',
    uploadDomain: 'https://edoctor-demo.com/_upload',
    epharmacyDomain: 'https://epharmacy.edoctor-demo.com',
};

const PRODUCTION: Hosts = {
    cmsApi: 'https://cms.edoctor.io',
    userApi: 'https://user.api.edoctor.io/v1',
    doctorApi: 'https://doctor.api.edoctor.io/v1',

    rootDomain: 'edoctor.io',
    domain: 'https://edoctor.io',
    homeDomain: 'https://edoctor.io',
    uploadAPI: 'https://upload.edoctor.io',
    uploadDomain: 'https://edoctor.io/_upload',
    epharmacyDomain: 'https://nhathuoc.edoctor.io',
};

export default ((nodeEnv: string) => {
    switch (nodeEnv) {
        case 'production':
            return PRODUCTION;
        case 'develop':
            return DEVELOPMENT;
        case 'staging':
            return STAGING;
        default:
            return PRODUCTION;
    }
})(process.env.RUN_ENV);
