import { FunctionComponent } from 'react';

// reactstrap
import { Modal as RTModal, ModalBody } from 'reactstrap';

// @Component
import Image from '@Components/Utils/Image';
import { useViewDetected } from '@Components/Utils/ViewDetected';

// NPM
import classnames from 'classnames';

// css
import styles from './Styles.module.scss';

interface UserDocumentProps {
    isModalOpen: boolean;
    handleCloseModal: (value: boolean) => void;
}

const UserDocument: FunctionComponent<UserDocumentProps> = ({ isModalOpen, handleCloseModal }) => {
    const { deviceType } = useViewDetected();

    const classNameUerDocument = classnames([
        styles.modal_user_document,
        {
            [styles.mobile]: ['mobile', 'tablet'].includes(deviceType),
        },
    ]);

    const toggle = () => handleCloseModal(!isModalOpen);

    return (
        <RTModal
            centered
            isOpen={isModalOpen}
            className={classNameUerDocument}
            contentClassName={styles.user_document_content}
        >
            <ModalBody className={styles.user_document_body}>
                <Image
                    width="24px"
                    height="24px"
                    onClick={toggle}
                    className={styles.close}
                    src="/assets/cong_dong/ic_close.png"
                />
                <div className={styles.content}>
                    <div className={styles.title}>Hướng dẫn đặt câu hỏi cho Bác sĩ</div>
                    <Image
                        width={'187px'}
                        height={'126px'}
                        className={styles.banner}
                        src="/assets/cong_dong/icon_user_question.png"
                    />
                    <div className={styles.body_title}>
                        Để được tiếp nhận và phản hồi chính xác cho câu hỏi của bạn, eDoctor gửi bạn một số lưu ý khi
                        đặt câu hỏi như sau:
                    </div>

                    <div className={styles.rules}>
                        <Image src="/assets/cong_dong/icon_check.png" width={'15px'} height={'13px'} />
                        <div>
                            Mô tả vấn đề bằng <b>tiếng Việt có dấu</b>, không viết tắt và không sử dụng ký hiệu.
                        </div>
                    </div>
                    <div className={styles.rules}>
                        <Image src="/assets/cong_dong/icon_check.png" width={'15px'} height={'13px'} />
                        <div>
                            Ghi rõ thông tin <b>độ tuổi, giới tính và chi tiết các triệu chứng, dấu hiệu</b> để Bác sĩ
                            nắm rõ hơn về tình trạng sức khỏe của bạn
                        </div>
                    </div>
                    <div className={styles.rules}>
                        <Image src="/assets/cong_dong/icon_check.png" width={'15px'} height={'13px'} />
                        <div>
                            Vui lòng cung cấp thêm thông tin chi tiết nếu có <b>tiền sử bệnh lý.</b>
                        </div>
                    </div>
                    <div className={styles.rules}>
                        <Image src="/assets/cong_dong/icon_check.png" width={'15px'} height={'13px'} />
                        <div>
                            Hình ảnh <b>đính kèm tối đa là 4 hình </b>cần rõ ràng, đúng vị trí bệnh lý hay vị trí tổn
                            thương.
                        </div>
                    </div>
                </div>
            </ModalBody>
        </RTModal>
    );
};

UserDocument.defaultProps = {
    isModalOpen: false,
    handleCloseModal: () => null,
};

export default UserDocument;
