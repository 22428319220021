interface initStateProps {
    modalThank?: boolean;
    modalAskQuestion?: boolean;
    modalUserDocument?: boolean;
}

interface actionType {
    modalThank?: boolean;
    modalAskQuestion?: boolean;
    modalUserDocument?: boolean;

    // type
    type: 'SET_MODAL_THANK' | 'SET_MODAL_ASK_QUESTION' | 'SET_MODAL_USER_DOCUMENT';
}

export const initStateData: initStateProps = {
    modalThank: false,
    modalAskQuestion: false,
    modalUserDocument: false,
};

const reducer = (state = initStateData, action: actionType): initStateProps => {
    switch (action.type) {
        case 'SET_MODAL_ASK_QUESTION': {
            return { ...state, modalAskQuestion: action.modalAskQuestion };
        }

        case 'SET_MODAL_THANK': {
            return { ...state, modalThank: action.modalThank };
        }
        case 'SET_MODAL_USER_DOCUMENT': {
            return { ...state, modalUserDocument: action.modalUserDocument };
        }

        // default
        default:
            return state;
    }
};

export default reducer;
