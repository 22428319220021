import { FunctionComponent } from 'react';
import classnames from 'classnames';

// Components
import LayoutContainer from '@Components/Layout/Components/Container';
import { useViewDetected, ViewMedia } from '@Components/Utils/ViewDetected';

// Styles
import styles from './BottomFooter.module.scss';

const BottomFooter: FunctionComponent = () => {
    const { deviceType } = useViewDetected();
    const colClassName = classnames(['col-md-3 col-sm-12', styles.col]);

    return (
        <div className={classnames([styles.wrapper, styles[deviceType]])}>
            <LayoutContainer className={styles.main}>
                <div className={styles.wrapper_content}>
                    <div className={colClassName}>
                        <div className={classnames([styles.title, styles.mb_4])}>
                            <span className={styles.bold}>Liên hệ</span>
                        </div>
                        <div className={styles.email}>Email: hotro@edoctor.vn</div>
                        <div>
                            Hotline: <span className={styles.bold}>1900 6115 - 0899909115</span>{' '}
                        </div>
                    </div>
                    <div className={colClassName}>
                        <div className={classnames([styles.title, styles.mb_4])}>
                            <span className={styles.bold}>Trụ sở Hồ Chí Minh</span>
                        </div>
                        <div>69 Đinh Bộ Lĩnh, Phường 24, Quận Bình Thạnh.</div>
                    </div>

                    <div className={colClassName}>
                        <div className={classnames([styles.title, styles.mb_4])}>
                            <span className={styles.bold}>Chi nhánh TP. Hà Nội</span>
                        </div>
                        <div>Lầu 2, Toà A3 Ecolife, số 58 Tố Hữu, Nam Từ Liêm.</div>
                    </div>
                    <div className={colClassName}>
                        <div className={classnames([styles.title, styles.mb_4])}>
                            <span className={styles.bold}>Chi nhánh TP. Cần Thơ</span>
                        </div>
                        <div>368B Nguyễn Văn Cừ nối dài, P. An Khánh, Q. Ninh Kiều.</div>
                    </div>
                </div>
                <div className={classnames(styles.wrapper_bct, styles.wrapper_content)}>
                    <div className={classnames('col-md-9 col-sm-12', styles.col, styles.col_bct)}>
                        <div className={classnames([styles.title, styles.mb_4])}>
                            <span className={styles.bold}>Công ty Cổ phần eDoctor</span>
                        </div>
                        <div className={styles.text}>
                            <div>Đại diện pháp luật: Ông Vũ Thanh Long</div>
                            <ViewMedia is="desktop">
                                <span> | </span>
                            </ViewMedia>
                            <div> GPDKKD số 0106462477 do Sở KH và ĐT TP.HCM cấp ngày 21/02/2014</div>
                        </div>
                    </div>
                    <div className={classnames(colClassName, styles.col_bct)}>
                        <a href="http://online.gov.vn/Home/WebDetails/11009" target="_blank" rel="noreferrer">
                            <img src="/assets/footer/bct.svg" />

                        </a>
                    </div>
                </div>
            </LayoutContainer>
        </div>
    );
};

export default BottomFooter;
