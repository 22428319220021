import React, { FunctionComponent, useState, useEffect, ChangeEvent, useRef, useCallback } from 'react';

// @Components
import Image from '@Components/Utils/Image';
import Timeout from '@Components/Utils/Timeout';
// import ImgLazy from '@Components/Utils/Lazy/Image';
import { useAppStore } from '@Components/AppStore/Context';
import { useAlertContext } from '@Components/Alert/Context';
import { useAccountContext } from '@Components/Account/Contexts';
import { useViewDetected } from '@Components/Utils/ViewDetected';

// Page
import { useAskQuestion, useGetShowIconQuestion } from '@Pages/hoi-dap/Context';

// Lodash
import classnames from 'classnames';

// Lib
import uploadApi from '@Libs/api/upload';
import { phoneRegExp } from '@Libs/utils';
import questionApi from '@Libs/api/user/question';
import { getHttpErrorMessage, uploadHttp, useHttpContext } from '@Libs/http';

// NPM
import md5 from 'md5';
import Cookies from 'js-cookie';

// Formik
import * as Yup from 'yup';
import { useFormik } from 'formik';

// Utilities
import host from '@Config/host';
import authApi from '@Libs/api/user/auth';

// GA
import { trackingEvent } from '@Components/Utils/Lazy/Script';
import { viewPage } from '@Components/Utils/Lazy/Script';

// Styles
import layout from './FormQuestion.module.scss';

const initialValues = {
    email: '',
    pinNumber: '',
    phoneNumber: '',
    questionContent: '',
};

const validationSchemaLogin = Yup.object().shape({
    questionContent: Yup.string().required('Vui lòng nhập thông tin câu hỏi').min(50, 'Thông tin câu hỏi quá ngắn'),
});

const validationSchemaNotLogin = Yup.object().shape({
    pinNumber: Yup.string().required('Vui lòng nhập mã OTP').max(4, 'Tối đa 4 chữ số'),
    phoneNumber: Yup.string()
        .required('Vui lòng nhập số điện thoại')
        .matches(phoneRegExp, 'Số điện thoại không hợp lệ'),
    questionContent: Yup.string()
        .required('Vui lòng nhập thông tin câu hỏi')
        .min(50, 'Thông tin câu hỏi quá ngắn, cần tối thiểu 50 ký tự.'),
});

const DURATION = 60000;
const LIMIT_UPLOAD = 4;

interface TimeOutRef {
    reset: () => void;
}

interface FormQuestionProps {
    active?: boolean;
    actionPage?: string;
    className?: string;
    formClassName?: string;
}

const FormQuestion: FunctionComponent<FormQuestionProps> = ({ active, actionPage, className, formClassName }) => {
    const { deviceType } = useViewDetected();
    // useContext
    const { alert } = useAlertContext();
    const { setLoading } = useAppStore();
    const { userHttp, setToken } = useHttpContext();
    const { getModal, setModal } = useAskQuestion();
    const { summary, request } = useAccountContext();
    const { setFormQuestionHeight } = useGetShowIconQuestion();

    const classNameFormQuestion = classnames([
        {
            [layout.mobile]: ['mobile', 'tablet'].includes(deviceType),
            [layout.home]: getModal?.askQuestion,
            [layout.popup]: active,
            [layout.form_question_page_detail]: actionPage === 'detail' ? 'form_question_page_detail' : '',
        },
        className,
    ]);

    //use Ref
    const timeOutRef: any = useRef<TimeOutRef>(null);
    const formRef = useRef<HTMLFormElement>(null);

    // useState
    const [duration, setDuration] = useState(null);
    const [attachments, setAttachments] = useState<any>([]);
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [formData, setFomData] = useState({ fields: {}, resetForm: null });

    // Declare
    const self: { id: string } = (summary?.consumer?.profiles || []).find(({ relation }) => relation === 'self') || {};
    const { id: profileId = '' } = self || {};

    useEffect(() => {
        if (formRef) {
            setFormQuestionHeight(formRef.current.clientHeight);
        }
    }, []);

    useEffect(() => {
        if (isSubmit && profileId) {
            handleSubmitSendQuestion(formData?.fields, formData?.resetForm);
        }
    }, [isSubmit, profileId]);

    // handel upload image
    const handleOnChangeImage = async (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();

        const files = Array.from(event.target.files);

        const filesLength = event.target.files?.length;
        const attachmentsLength = attachments?.length;

        if (filesLength + attachmentsLength > LIMIT_UPLOAD) {
            return alert({
                type: 'error',
                title: 'Đã xảy ra lỗi',
                text: 'Tối đa 4 hình ảnh.',
            });
        }

        const uploadAttachments = await Promise.all(
            files.map((file: any) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve({
                            file,
                            id: md5(file.name),
                            isLoading: true,
                            attachmentId: null,
                            image: ev.target.result,
                        });
                    });
                    reader.addEventListener('error', () => {
                        reject(new Error('Upload images fail!'));
                    });
                    reader.readAsDataURL(file);
                });
            }),
        );
        handleImagesReview(uploadAttachments);

        // event tracking
        trackingEvent('nhấp vào "Thêm file đính kèm"', 'Đặt câu hỏi miễn phí');
    };

    // add Images
    const handleImagesReview = (uploadedAttachments) => {
        setAttachments([...attachments, ...uploadedAttachments]);
        viewPage({ path: '/funnel/asking/typing_attachments' });
    };

    // Call api upload
    const handleUploadImages = (uploadedAttachments) => {
        return Promise.all(
            uploadedAttachments.map(async (value, key) => {
                const result: { attachmentId: string; attachment: { id: string } } = await uploadApi(
                    uploadHttp,
                ).getAttachmentImage(value?.file, 'question_image');
                uploadedAttachments[key].isLoading = false;
                uploadedAttachments[key].id = result.attachment.id;
                uploadedAttachments[key].attachmentId = result.attachmentId;
            }),
        );
    };

    // show images
    const renderPreviewAttachments = () => {
        return (
            attachments.length > 0 &&
            attachments.map((attachment: { image: string; id: string }, key) => {
                const { image, id } = attachment;
                return (
                    <div className={layout.image_review} key={key}>
                        <Image src={image} className={layout.image_upload} />
                        <Image
                            width={'16px'}
                            height={'16px'}
                            className={layout.image_remove}
                            onClick={() => handleRemove(id)}
                            src={'/assets/cong_dong_v2/icon_remove.png'}
                        />
                    </div>
                );
            })
        );
    };

    // remove Image
    const handleRemove = (attachmentId: string) => {
        const newAttachment = attachments.filter((att) => att.id !== attachmentId);
        setAttachments(newAttachment);
    };

    // submit user noi login
    const handleUserNotLogin = async (fields, resetForm) => {
        handleVerifyLogin(fields, resetForm);
    };

    // handleVerifyLogin
    const handleVerifyLogin = async (fields, resetForm) => {
        const { phoneNumber, pinNumber } = fields;
        try {
            const payload = {
                phoneNumber,
                pinNumber,
            };
            const responseAuth: { token: string } = await authApi(userHttp).signInSmsVerify(payload);

            Cookies.set('token', responseAuth.token, {
                expires: 365,
                ...(() => {
                    if (process.env.NODE_ENV !== 'development') {
                        return { domain: host.rootDomain };
                    }
                    return null;
                })(),
            });
            setToken(responseAuth.token);

            setFomData({ fields, resetForm });
            setIsSubmit(true);
        } catch (error) {
            alert({
                type: 'error',
                title: 'Đã xảy ra lỗi',
                text: getHttpErrorMessage(error),
            });
        }
    };

    // handle send OTP
    const handleSendOTP = async (phoneNumber: string) => {
        if (phoneRegExp.test(phoneNumber)) {
            try {
                await request(phoneNumber);
                setDuration(DURATION);
            } catch (error) {
                alert({
                    type: 'error',
                    title: 'Đã xảy ra lỗi',
                    text: getHttpErrorMessage(error),
                });
            }
        }
        // event tracking
        trackingEvent('nhấp vào nút "Gửi OTP"', 'Đặt câu hỏi miễn phí');
    };

    // await request(phoneNumber);
    const handleResendPin = async (phoneNumber: string) => {
        try {
            await request(phoneNumber);
            setDuration(DURATION);
            timeOutRef.current.reset();
        } catch (error) {
            alert({
                type: 'error',
                title: 'Đã xảy ra lỗi',
                text: getHttpErrorMessage(error),
            });
        }
    };

    // show / hidden modal user document
    const handelUserDocument = () => {
        setModal.UserDocument(true);
        // event tracking
        trackingEvent('nhấp vào "Xem hướng dẫn"', 'Đặt câu hỏi miễn phí');
    };

    // handle data form submit
    const handleSubmitSendQuestion = useCallback(
        async (fields, resetForm) => {
            setLoading(true);
            try {
                await handleUploadImages(attachments);
                const attachmentIds = [];
                attachments.forEach((element: { attachmentId: string }) => {
                    attachmentIds.push(element.attachmentId);
                });

                const body: any = {
                    profileId,
                    attachmentIds,
                    questionContent: fields?.questionContent,
                };

                // call API
                await questionApi(userHttp).newQuestion(body);

                // Funnel
                viewPage({ path: '/funnel/asking/completed' });

                // event tracking
                trackingEvent('câu hỏi đặt thành công', 'Đặt câu hỏi miễn phí');

                // rest
                resetForm();

                // modal
                setAttachments([]);
                setIsSubmit(false);
                setFomData({ fields: {}, resetForm: null });

                // modal
                setModal.Thank(true);
                setModal.askQuestion(false);
            } catch (error) {
                alert({
                    type: 'error',
                    title: 'Đã xảy ra lỗi',
                    text: getHttpErrorMessage(error),
                });
            } finally {
                setLoading(false);
            }
        },
        [isSubmit, profileId, attachments],
    );

    // submit
    const { handleChange, handleSubmit, values, errors, touched, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: profileId ? validationSchemaLogin : validationSchemaNotLogin,
        onSubmit: (fields, { resetForm }) => {
            try {
                profileId ? handleSubmitSendQuestion(fields, resetForm) : handleUserNotLogin(fields, resetForm);
            } catch (error) {
                alert({
                    type: 'error',
                    title: 'Đã xảy ra lỗi',
                    text: getHttpErrorMessage(error),
                });
            }
            // event tracking
            trackingEvent('nhấp vào nút "Gửi câu hỏi"', 'Đặt câu hỏi miễn phí');
        },
    });

    const onChange = (event) => {
        const { value, name } = event.target;
        setFieldValue(name, value);
        trackingEvent('nhấp vào phần "nhập mã OTP"', 'Đặt câu hỏi miễn phí');
    };

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={classNameFormQuestion}>
            <div className={classnames([layout.form_answer, formClassName])}>
                <div className={layout.form_answer__header}>
                    <div className={layout.title}>Đặt câu hỏi miễn phí</div>
                    <div className={layout.rules} onClick={handelUserDocument}>
                        <Image width={'16px'} height={'16px'} src={'/assets/cong_dong_v2/info.png'} />
                        Xem hướng dẫn
                    </div>
                </div>
                <div className={layout.form_answer__content}>
                    <textarea
                        rows={5}
                        name="questionContent"
                        onChange={handleChange}
                        value={values.questionContent}
                        placeholder="Lưu ý mô tả chi tiết nội dung muốn hỏi bác sĩ:&#10;- Giới tính, tuổi;&#10;- Triệu chứng gặp phải;&#10;- Bắt đầu có triệu chứng khi nào;&#10;- Đã dùng biện pháp xử lý nào chưa?"
                    />
                    {errors.questionContent && touched.questionContent && (
                        <div className={classnames([layout.input_feedback, layout.input_feedback_questionContent])}>
                            {errors.questionContent}
                        </div>
                    )}

                    <div className={layout.images}>{renderPreviewAttachments()}</div>
                    <div className={layout.upload}>
                        {attachments?.length < 4 && (
                            <input type="file" accept="image/*" multiple={true} onChange={handleOnChangeImage} />
                        )}
                        <Image width={'25px'} height={'25px'} src="/assets/cong_dong_v2/attach.png" />
                        <span>
                            Thêm file đính kèm <strong>(Tối đa 4 hình, chỉ Bác sĩ có thể xem)</strong>
                        </span>
                    </div>
                </div>
                {!profileId && (
                    <div className={layout.sendNotification}>
                        <div className={layout.phone_number}>
                            <div className={layout.input_group}>
                                <span>+84</span>
                                <input
                                    type="tel"
                                    name="phoneNumber"
                                    onChange={handleChange}
                                    value={values.phoneNumber}
                                    placeholder="Số điện thoại của bạn"
                                />

                                {duration ? (
                                    <Timeout ref={timeOutRef} milliseconds={duration}>
                                        {({ duration, end }) => (
                                            <span
                                                className={layout.duration}
                                                onClick={() => (end ? handleResendPin(values.phoneNumber) : null)}
                                            >
                                                Gửi lại {!end ? `(${duration / 1000}s)` : ''}
                                            </span>
                                        )}
                                    </Timeout>
                                ) : (
                                    <span className={layout.send_otp} onClick={() => handleSendOTP(values.phoneNumber)}>
                                        Gửi OTP
                                    </span>
                                )}
                            </div>
                            {errors.phoneNumber && touched.phoneNumber && (
                                <div className={classnames([layout.input_feedback, layout.input_feedback_phoneNumber])}>
                                    {errors.phoneNumber}
                                </div>
                            )}
                        </div>
                        <div className={layout.otp}>
                            <input
                                type="text"
                                name="pinNumber"
                                onChange={onChange}
                                value={values.pinNumber}
                                placeholder="Nhập mã OTP được gửi vào điện thoại"
                            />

                            {errors.pinNumber && touched.pinNumber && (
                                <div className={classnames([layout.input_feedback, layout.input_feedback_pinNumber])}>
                                    {errors.pinNumber}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <button type="submit">Gửi câu hỏi</button>
            </div>
        </form>
    );
};

FormQuestion.defaultProps = {
    active: false,
};

export default FormQuestion;
