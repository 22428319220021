import { AxiosInstance } from 'axios';
import { useHttp } from '@Libs/http';
import { responseInterface } from 'swr';

interface notificationsRequest {
    limit?: number;
    offset?: number;
    types: string[]
}

interface RequestMarkRead {
    isNotificationId: boolean;
}

const notificationApi = (http: AxiosInstance ) => ({
    countNotification: async (types: string[] = []) => {
        const response = await http.get('/notifications/count', {
            params: {
                types,
                readStatus: 0,
            }
        } );
        return response.data || {};
    },
    notifications: async (queries: notificationsRequest) => {
        const response = await http.get('notifications', {
            params: {
                offset: 0,
                limit: 10,
                ...queries
            }
        });
        return response.data || {};
    },
    postMarkReadNotification: async (notificationId: string, payload?: RequestMarkRead) => {
        const response = await http.post(`/notifications/${notificationId}/mark_read`, payload);
        return response.data;
    },
});

const COUNT_NOTIFICATION_KEY = 'count_notification_key';
export const useCountNotification = (types: string[], key = COUNT_NOTIFICATION_KEY): responseInterface<any, any> =>
    useHttp(key, async ({ userHttp }) => await notificationApi(userHttp).countNotification(types), {
        auth: true,
    });

const NOTIFICATION_KEY = 'notification_key';
export const useNotification = (key = NOTIFICATION_KEY, queries?: notificationsRequest): responseInterface<any, any> =>
    useHttp(key, async ({ userHttp }) => await notificationApi(userHttp).notifications(queries), {
        auth: true,
    });

export default notificationApi;