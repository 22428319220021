import { createContext, FunctionComponent, useContext, useState, useCallback, useEffect } from 'react';

// Utilities
import Cookies from 'js-cookie';
import authApi from '@Libs/api/user/auth';
import { useSummary } from '@Libs/api/user/account';

// Contexts
import { getHttpErrorMessage, useHttpContext } from '@Libs/http';
import { useAppStore } from '@Components/AppStore/Context';

// Config
import host from '@Config/host';
import { useAlertContext } from '@Components/Alert/Context';

type Actions = 'REQUEST' | 'VERIFY';

interface AccountContextType {
    summary: any;
    action: Actions;
    phoneNumber: string;
    isModalOpen: boolean;

    toggleModal: () => void;
    setAction: (action: Actions) => void;
    setPhoneNumber: (phoneNumber: string) => void;

    signout: () => void;
    request: (phoneNumber: string) => void;
}

const initState: AccountContextType = {
    summary: {},
    phoneNumber: '',
    action: 'REQUEST',
    isModalOpen: false,

    setAction: () => null,
    toggleModal: () => null,
    setPhoneNumber: () => null,

    signout: () => null,
    request: () => null,
};

export const AccountContext = createContext(initState);

export const useAccountContext = (): AccountContextType => useContext(AccountContext);

interface ProviderProps {
    token?: string;
}

export const Provider: FunctionComponent<ProviderProps> = (props) => {
    const { alert } = useAlertContext();
    const { setLoading } = useAppStore();
    const [action, setAction] = useState<Actions>('REQUEST');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [isModalOpen, setModalOpen] = useState<boolean>(false);

    const { token, userHttp } = useHttpContext();

    const toggleModal = useCallback(() => {
        setModalOpen(!isModalOpen);
    }, [isModalOpen]);

    const { data: summary } = useSummary(token);

    useEffect(() => {
        if (summary?.consumer?.id) {
            const userID = summary?.consumer?.id;
            const selfProfile = (summary?.consumer?.profiles || []).find(({ relation }) => relation === 'self') || {};

            const gender = selfProfile?.gender || 'unknow';
            const age = selfProfile?.birthDate_year ? new Date().getFullYear() - +selfProfile?.birthDate_year : 'uknow';

            gtag('set', 'user_properties', {
                user_id: userID,
                ...{ age, Age: age },
                ...{ gender, Gender: gender },
            });
        }
    }, [summary?.consumer?.id]);

    const request = async (phoneNumber) => {
        await authApi(userHttp).signInSmsRequest({ phoneNumber });
    };

    const signout = async () => {
        setLoading(true);

        try {
            authApi(userHttp).signOut();

            Cookies.remove('token', { domain: host.rootDomain });
            Cookies.remove('token');
            localStorage.clear();
            window.location.reload();
        } catch (error) {
            alert({
                type: 'error',
                title: 'Đã xảy ra lỗi',
                text: getHttpErrorMessage(error),
            });
        }
    };

    const providerValue: AccountContextType = {
        action,
        summary,
        isModalOpen,
        phoneNumber,

        setAction,
        toggleModal,
        setPhoneNumber,

        signout,
        request,
    };

    return <AccountContext.Provider value={providerValue}>{props.children}</AccountContext.Provider>;
};

Provider.defaultProps = {
    token: null,
};

export const withAccount = (WrappedComponent: FunctionComponent<any>) => ({ token, ...props }) => (
    <Provider token={token}>
        <WrappedComponent {...props} />
    </Provider>
);
