import { FunctionComponent, useCallback } from 'react';

// reactstrap
import { Modal as RTModal, ModalBody } from 'reactstrap';

// Component
import UserDocument from '../UserDocument';
import ThankYou from '../ThankYou';

// @Components
import Image from '@Components/Utils/Image';

// Component
import FormQuestion from '../FormQuestion';

// css
import styles from './Styles.module.scss';
import { useAskQuestion } from '@Pages/hoi-dap/Context';
import classnames from 'classnames';

interface ModalProps {
    isModalOpen: boolean;
    handleCloseModal: (value: boolean) => void;
}

const Modal: FunctionComponent<ModalProps> = ({ isModalOpen, handleCloseModal }) => {
    const toggle = () => handleCloseModal(!isModalOpen);

    const { getModal, setModal } = useAskQuestion();

    const handleUserDocumentClose = useCallback(() => {
        setModal.UserDocument(false);
    }, [getModal.userDocument]);

    const handleThankClose = useCallback(() => {
        setModal.Thank(false);
    }, [getModal.thank]);

    const classNameFormQuestion = classnames([
        styles.modalQuestion_header,
        {
            [styles.popup]: getModal?.askQuestion,
        },
    ]);

    return (
        <>
            <ThankYou isModalOpen={getModal.thank} handleCloseModal={handleThankClose} />
            <UserDocument isModalOpen={getModal.userDocument} handleCloseModal={handleUserDocumentClose} />
            <RTModal isOpen={isModalOpen} centered className={styles.modalQuestion}>
                <ModalBody className={classNameFormQuestion}>
                    <Image
                        width="24px"
                        height="24px"
                        onClick={toggle}
                        className={styles.close}
                        src="/assets/cong_dong/ic_close.png"
                    />
                    <FormQuestion active={getModal?.askQuestion} />
                </ModalBody>
            </RTModal>
        </>
    );
};

Modal.defaultProps = {
    isModalOpen: false,
    handleCloseModal: () => null,
};

export default Modal;
